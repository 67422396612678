import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IFolio, IProject, ProjectStatusEnum } from '@js-elec/js-elec-types';
import { BsModalService } from "ngx-bootstrap/modal";
import { of, Subject } from "rxjs";
import { debounceTime, filter, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { FolioService } from "src/app/folio/folio.service";
import { ModalConfirmComponent } from "src/app/shared/components/modals/modal-confirm.component";
import { ModalInputComponent } from "src/app/shared/components/modals/modal-input.component";
import * as XLSX from "xlsx";
import { ProjectService } from "../project.service";

@Component({
  selector: "project-properties",
  templateUrl: "./project-properties.component.html",
})
export class ProjectPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project!: IProject;
  @Output() folioSelected = new EventEmitter<IFolio>();
  projectForm!: FormGroup;

  status = ProjectStatusEnum;

  destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private projectSvc: ProjectService,
    private folioSvc: FolioService,
    private modal: BsModalService,
  ) { }

  ngOnInit() {
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project) {
      this.initForm();
    }
  }

  initForm() {
    this.projectForm = this.fb.group({
      name: [this.project.name, Validators.required],
    });

    this.projectForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(400),
        filter(_ => this.projectForm.valid),
        switchMap(v => this.save(v as IProject)),
        tap((p: IProject) => {
          (this.projectSvc.currentProject.value as IProject).name = p.name
          if (this.projectForm.get('name')?.value !== p?.name)
            this.projectForm.get('name')?.setValue(p?.name)
        })
      )
      .subscribe();
  }

  validateProject() {
    this.modal.show(ModalConfirmComponent, {
      initialState: {
        title: 'Souhaitez-vous valider votre projet maintenant ?',
      }
    })
    this.modal.onHide.pipe(
      take(1),
      filter(r => r === true),
      switchMap(_ => this.projectSvc.validateProject(this.project.id as string))
    ).subscribe()
  }

  addFolio() {
    this.modal.show(ModalInputComponent, {
      initialState: {
        title: "Nouvelle Page",
        fields: [
          { name: "name", label: "Nom de la page", type: "text", required: true },
        ],
      },
    });

    this.modal.onHide.pipe(take(1)).subscribe((info) => {
      if (typeof info === "object") {
        this.folioSvc.addFolio(this.project, info).subscribe();
      }
    });
  }

  selectFolio(folio: IFolio) {
    this.folioSelected.emit(folio);
  }

  save(v: IProject) {
    return this.projectSvc
      .updateProject(this.project.id as string, v)
  }

  materialList() {
    const project = this.projectSvc.currentProject.value as IProject
    const materials = this.projectSvc.getMaterials(project)

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    materials.forEach(folioMaterial => {

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(folioMaterial.materials);
      XLSX.utils.book_append_sheet(wb, ws, folioMaterial.folio);
    })

    /* save to file */
    XLSX.writeFile(wb, project.name + "_materiels" + ".xlsx");
  }

  conduitsList() {
    const project = this.projectSvc.currentProject.value as IProject
    const conduits = this.projectSvc.getConduits(project)

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    conduits.forEach(folioConduit => {

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(folioConduit.conduits);
      XLSX.utils.book_append_sheet(wb, ws, folioConduit.folio);
    })

    /* save to file */
    XLSX.writeFile(wb, project.name + "_conduits" + ".xlsx");
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
