// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export interface IEnvironment {
  production: boolean;
  apiHost: string;
  goLicense: string;
  storageUrl: string;
  featuresUrl: string;
  appName: string;
}

export const environment: IEnvironment = {
  production: false,
  apiHost: 'https://api.cpreylec.cadbld.eu',
  // apiHost: 'http://localhost:3000',
  goLicense: "73f943e1b56328a800ca0d2b113f69ef1bb37f3b9ed01ef4595441f0ea5c6d413089ef2d018489c781fe1afb1979c0898f956c2d9f44172be16483da43b0d6f8a02770b3170a588cbb0026",
  storageUrl: 'https://storage.googleapis.com/cpreylec-data-dev/',
  featuresUrl: 'https://storage.googleapis.com/cpreylec-data-dev/features.json',
  appName: "CPreylec"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
