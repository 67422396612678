import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../../user.service';

@Component({
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  profileForm!: FormGroup;
  passwordForm!: FormGroup;

  isProfileFormSubmitted = false;
  isPasswordFormSubmitted = false;
  jobList!: any;
  jobStrings!: string

  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
  ) {

  }

  ngOnInit(): void {
    const user = this.userSvc.currentUser.value;
    this.profileForm = this.fb.group({
      firstName: [user?.firstName, Validators.required],
      lastName: [user?.lastName, Validators.required],
      email: [user?.email, Validators.required],
    });

    this.passwordForm = this.fb.group(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ]),
        oldPassword: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validator: passwordConfirming }
    );

    function passwordConfirming(
      c: AbstractControl
    ): { invalid: boolean } | undefined {
      if (c.get('password')?.value !== c.get('confirmPassword')?.value) {
        return { invalid: true };
      }
      return;
    }
  }

  updateProfile() {
    this.isProfileFormSubmitted = true;
    if (this.profileForm.invalid) return;

    this.userSvc.updateMe(this.profileForm.value).subscribe();
    this.isProfileFormSubmitted = false;
  }
  changePassword() {
    this.isPasswordFormSubmitted = true;
    if (this.passwordForm.invalid) return;

    this.userSvc
      .changePassword(
        this.passwordForm.value.oldPassword,
        this.passwordForm.value.password
      ).subscribe();

    this.isPasswordFormSubmitted = false;
  }
}
