import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { FolioModule } from "../folio/folio.module";
import { SharedModule } from "../shared/shared.module";
import { ProjectCreateComponent } from "./components/project-create.component";
import { ProjectListComponent } from "./components/project-list.component";
import { ProjectPropertiesComponent } from "./components/project-properties.component";
import { ProjectSelectComponent } from "./components/project-select.component";
import { ProjectService } from "./project.service";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,

    ModalModule.forRoot(),
    AngularSvgIconModule,

    SharedModule,
    FolioModule,
  ],
  providers: [ProjectService],
  declarations: [
    ProjectListComponent,
    ProjectCreateComponent,
    ProjectSelectComponent,
    ProjectPropertiesComponent,
  ],
  exports: [ProjectListComponent, ProjectPropertiesComponent],
})
export class ProjectModule { }
