import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { GojsAngularModule } from "gojs-angular";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { FloorPlannerComponent } from "./diagram/floor-planner.component";
import { DataInspectorComponent } from "./inspector/data-inspector.component";
import { PropValuePipe } from "./inspector/prop-value.pipe";
import { PropertyChoicesPipe } from "./inspector/property-choices.pipe";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    GojsAngularModule,
    AngularSvgIconModule,
    TooltipModule.forRoot(),
  ],
  declarations: [
    FloorPlannerComponent,
    DataInspectorComponent,

    PropertyChoicesPipe,
    PropValuePipe,
  ],
  exports: [FloorPlannerComponent],
})
export class FloorPlannerModule { }
