import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IFolio, IProject } from '@js-elec/js-elec-types';
import { Diagram, Model, ObjectData } from "gojs";
import { BehaviorSubject, Observable, Subject, combineLatest, interval } from "rxjs";
import { debounce, debounceTime, delay, distinctUntilChanged, distinctUntilKeyChanged, filter, map, startWith, tap } from "rxjs/operators";
import { FolioService, IOpenedFolio } from "../folio.service";
import getsetFunctions from "src/app/floorPlanner/inspector/getset-functions";
import { FolioPropertyNames } from "../folio.constants";
import { Floorplan } from "src/app/floorPlanner/diagram/floorplan-diagram";

@Component({
  selector: "folio-structure",
  templateUrl: "./folio-structure.component.html",
})
export class FolioStructureComponent implements OnInit, OnDestroy {
  @Input() project!: IProject;
  selectedFolio$: BehaviorSubject<IOpenedFolio | null>;

  tree$!: Observable<any[]>

  constructor(
    private folioSvc: FolioService,
  ) {
    this.selectedFolio$ = this.folioSvc.selectedFolio$;

    this.tree$ = combineLatest(
      this.selectedFolio$,
      interval(2000)
    )
      .pipe(
        map(([v]) => v),
        debounceTime(600),
        filter((openedFolio): openedFolio is IOpenedFolio => !!openedFolio),
        // distinctUntilChanged((folioA, folioB) => folioA.folio.id !== folioB.folio.id),
        map(openedFolio => {
          const model: any = openedFolio.diagram!.model;
          const { nodeDataArray, linkDataArray } = model;
          return this.folioSvc.buildTree({ nodeDataArray, linkDataArray })
        }),
        // tap(console.log)
      )
  }

  ngOnInit() {

  }


  toggleVisibility(selectedNode: any) {

    function updateNodeVisibility(diagram: Floorplan, node: any, value: boolean) {
      const nodeObject = diagram.findNodeForKey(node.key)
      if (!nodeObject)
        return;

      diagram.startTransaction("set property");
      diagram.model.setDataProperty(nodeObject.data, 'visible', value);
      diagram.commitTransaction("set property");
      (node.children ?? []).forEach((child: any) => updateNodeVisibility(diagram, child, value))
    }
    // function updateCheckBoxesDown(node: ObjectData, value: boolean) {
    //   node.diagram.startTransaction("set property");
    //   node.diagram.model.setDataProperty(node.data, 'visible', value);
    //   node.diagram.commitTransaction("set property");
    //   node.findTreeChildrenNodes()
    //     .filter((n: any) => n.)
    //     .each((child: any) => updateCheckBoxesDown(child, value))
    // }

    const openedFolio = this.folioSvc.selectedFolio$.value
    if (openedFolio && openedFolio.diagram) {
      updateNodeVisibility(openedFolio.diagram, selectedNode, selectedNode.visible === undefined ? false : !selectedNode.visible)
      // const model: Model = openedFolio.diagram.model;
      // const nodeToToggle = model.nodeDataArray.find(node => node.key === selectedNode.key)
      // if (nodeToToggle) {
      //   const nodeObject = openedFolio.diagram.findNodeForKey(nodeToToggle.key)
      //   if (nodeObject)
      //     updateCheckBoxesDown(nodeObject, nodeObject.data.visible === undefined ? false : !nodeObject.data.visible)
      // }
    }
  }

  ngOnDestroy() { }
}
