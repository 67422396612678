import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "modal-input",
  templateUrl: "./modal-input.component.html",
})
export class ModalInputComponent implements OnInit {
  inputForm!: FormGroup;
  @Input() fields!: {
    name: string;
    label: string;
    type: "text";
    required: boolean;
  }[];
  @Input() data?: any;
  @Input() title?: string;
  @Input() buttonLabel = "Ok";
  @Input() cancelLabel?: string;

  constructor(
    public modal: BsModalRef,
    private modalSvc: BsModalService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    const inputs: any = {};
    this.fields.forEach((field) => {
      inputs[field.name] = this.fb.control(
        this.data !== undefined && this.data[field.name] ? this.data[field.name] : "",
        field.required ? Validators.required : []
      );
    });
    this.inputForm = this.fb.group(inputs);
  }

  close() {
    this.modalSvc.setDismissReason('');
    this.modal.hide();
  }

  sendValue() {
    this.modalSvc.setDismissReason(this.inputForm.value);
    this.modal.hide();
  }
}
