import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UserRolesEnum } from '@js-elec/js-elec-types';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Directive({
  selector: '[isSuperAdmin]'
})
export class IsSuperAdminDirective implements OnInit, OnDestroy {
  isVisible = false;

  stop$ = new Subject()

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authSvc: AuthService
  ) { }

  ngOnInit() {
    this.authSvc.currentAuth.pipe(
      takeUntil(this.stop$),
    ).subscribe(auth => {
      if (!auth) {
        this.viewContainerRef.clear();
      }
      if (auth !== null && auth.user.role <= UserRolesEnum.superAdmin) {
        if (!this.isVisible) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}