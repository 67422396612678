import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "../../auth.service";

@Component({
  selector: "remote-register",
  templateUrl: "remote-register.component.html",
})
export class RemoteRegisterComponent implements OnInit {
  error = false;
  created = false;
  userEmail = "";
  constructor(
    // private router: Router,
    private route: ActivatedRoute,
    private authSvc: AuthService,
  ) { }

  ngOnInit() {
    this.userEmail = this.route.snapshot.queryParams['email']
    if (this.userEmail && this.userEmail.length > 0)
      this.authSvc.remoteRegister(this.userEmail.replace(' ', '+').trim().toLocaleLowerCase())
        .pipe(
          catchError(err => {
            this.error = true;
            throw err;
          }),
          tap(_user => {
            this.created = true;
          })
        )
        .subscribe()
  }


}
