import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { EditorComponent } from './editor/editor.component';
import { LayoutEditorComponent } from "./layout/layout-editor.component";
import { ProjectGuard } from "./project/services/project.guard";
import { ProjectSelectComponent } from "./project/components/project-select.component";

const routes: Routes = [
  {
    path: 'connect',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: "editor",
    component: LayoutEditorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ProjectSelectComponent
      },
      {
        path: ":projectId",
        canActivate: [ProjectGuard],
        component: EditorComponent
      }
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "**",
    redirectTo: "/connect/login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
