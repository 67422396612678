import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ModalConfirmComponent } from '../shared/components/modals/modal-confirm.component';
import { NotificationService } from '../shared/services/notify.service';
import { ApiListResult, IUser, Models, UserRolesEnum } from '@js-elec/js-elec-types/dist';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser = new BehaviorSubject<Models.User | null>(null);
  constructor(
    private httpClient: HttpClient,
    private notifySvc: NotificationService,
    private modalSvc: BsModalService,
    private router: Router,
  ) { }

  loadUser(): Observable<Models.User> {
    return this.getMe().pipe(
      map(r => new Models.User(r)),
      tap((user) => {
        this.currentUser.next(user);
      })
    );
  }

  getMe() {
    return this.httpClient.get<IUser>(
      `${(environment as any).apiHost}/user/me`
    );
  }

  getUsers(
    query: any = {},
    page = 1,
    limit = 10
  ): Observable<{ count: number; rows: IUser[] }> {
    let queryString = Object.keys(query)
      .filter((prop) => !!query[prop] && query[prop] !== '')
      .reduce((acc, k) => {
        return (acc += `${k}=${query[k]}&`);
      }, '');
    queryString += `page=${page}&limit=${limit}`;
    return this.httpClient.get<{ count: number; rows: IUser[] }>(
      `${(environment as any).apiHost}/user?${queryString}`
    );
  }

  getUser(
    id: string
  ): Observable<IUser> {
    return this.httpClient.get<IUser>(
      `${(environment as any).apiHost}/user/${id}`
    );
  }

  updateMe(user: IUser): Observable<Models.User> {
    return this.httpClient
      .put<IUser>(`${(environment as any).apiHost}/user/me`, user)
      .pipe(
        map(r => new Models.User(r)),
        tap((updatedUser) => {
          if (this.currentUser.value?.id === updatedUser.id) {
            this.currentUser.next(updatedUser);
          }
        }),
        tap(() => {
          this.notifySvc.notification(
            'info',
            'Vos informations ont été mises à jour'
          );
        })
      );
  }

  updateUser(id: string, user: IUser): Observable<Models.User> {
    return this.httpClient
      .put<IUser>(`${(environment as any).apiHost}/user/${id}`, user)
      .pipe(
        map(r => new Models.User(r)),
        tap(() => {
          this.notifySvc.notification(
            'info',
            'Informations ont été mises à jour'
          );
        })
      );
  }

  disableUser(user: IUser) {
    this.modalSvc.show(ModalConfirmComponent, {
      initialState: {
        title: "Désactiver l'utilisateur",
        body: "L'utilisateur ne pourra plus se connecter"
      }
    })
    this.modalSvc.onHide
      .pipe(
        take(1),
        filter(r => r === true),
        switchMap(_ => this.httpClient.put<IUser>(`${(environment as any).apiHost}/user/${user.id}/disable`, {})),
        tap(updatedUser => user.disabled = updatedUser.disabled),
        tap(() => {
          this.notifySvc.notification(
            'info',
            "L'utilisateur a été désactivé"
          );
        })
      ).subscribe()
  }

  deleteUser(id: string) {
    this.modalSvc.show(ModalConfirmComponent, {
      initialState: {
        title: "Supprimer l'utilisateur",
        body: "L'utilisateur et toutes ses données seront supprimées"
      }
    })
    this.modalSvc.onHide
      .pipe(
        take(1),
        filter(r => r === true),
        switchMap(_ => this.httpClient.delete<IUser>(`${(environment as any).apiHost}/user/${id}`, {})),
        tap(_ => this.router.navigate(['/', 'admin', 'users'])),
        tap(() => {
          this.notifySvc.notification(
            'info',
            "L'utilisateur a été supprimé"
          );
        })
      ).subscribe()
  }

  acceptCgv(): Observable<Models.User> {
    return this.httpClient.put<IUser>(
      `${(environment as any).apiHost}/user/me/acceptCgv`, { type: 'cgv' }
    ).pipe(
      map(r => new Models.User(r)),
    );
  }

  updateUserRole(userId: string, role: UserRolesEnum): Observable<Models.User> {
    return this.httpClient
      .put<IUser>(`${(environment as any).apiHost}/user/${userId}/role`, { role })
      .pipe(
        map(r => new Models.User(r)),
        tap(() => {
          this.notifySvc.notification(
            'info',
            'Informations mises à jour'
          );
        }),
        catchError(err => {
          this.notifySvc.notification('error', "Erreur, informations manquantes");
          throw err;
        })
      );
  }

  askPassword(email: string): Observable<Models.User> {
    return this.httpClient.put<IUser>(
      `${(environment as any).apiHost}/user/forgot-password`,
      { email }
    ).pipe(
      map(r => new Models.User(r)),
    );
  }

  changePasswordWithToken(token: string, password: string): Observable<Models.User> {
    return this.httpClient.put<IUser>(
      `${(environment as any).apiHost}/user/change-password/${token}`,
      { password }
    ).pipe(
      map(r => new Models.User(r))
    );
  }

  changePassword(oldPassword: string, password: string) {
    return this.httpClient
      .put<IUser>(`${(environment as any).apiHost}/user/me/password`, {
        oldPassword,
        password,
      })
      .pipe(
        catchError((err) => {
          this.notifySvc.notification(
            'error',
            'Merci de vérifier que votre mot de passe actuel a bien été saisi.'
          );
          throw err;
        }),
        map(r => new Models.User(r)),
        tap(() => {
          this.notifySvc.notification(
            'info',
            'Votre mot de passe a bien été mis à jour'
          );
        })
      );
  }

  deleteMe() {
    return this.httpClient.delete<IUser>(
      `${(environment as any).apiHost}/user/`
    );
  }

  dashboardStats() {
    return this.httpClient.get<any>(
      `${(environment as any).apiHost}/stats`
    );
  }

  lastConnectedUsers() {
    return this.httpClient.get<ApiListResult<IUser>>(
      `${(environment as any).apiHost}/user/lastConnected`
    );
  }

  isInRole(user: IUser, roles: UserRolesEnum | UserRolesEnum[]) {
    if (Array.isArray(roles)) {
      return !roles.length || roles.includes(user.role)
    }
    return user.role <= roles
  }


}
