import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PasswordRequestComponent } from './components/password-request/password-request.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RemoteRegisterComponent } from './components/remote-register/remote-register.component';
import { FeaturesService } from '../shared/services/features.service';
import { ErrorPageComponent } from '../shared/components/error-page/error-page.component';
import { FeatureGuard } from '../shared/guards/features.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        canActivate: [FeatureGuard],
        path: 'register',
        data: { feature: 'authentication.register' },
        component: RegisterComponent
      },
      {
        canActivate: [FeatureGuard],
        path: 'remoteRegister',
        data: { feature: 'authentication.remoteRegister' },
        component: RemoteRegisterComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'password-request',
        component: PasswordRequestComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
