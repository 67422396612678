import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiListResult, IProject } from '@js-elec/js-elec-types';
import { BehaviorSubject, Observable } from "rxjs";
import { FolioService } from "src/app/folio/folio.service";
import { ProjectService } from "../project.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ProjectCreateComponent } from "./project-create.component";
import { map, switchMap } from "rxjs/operators";

@Component({
  selector: "select-project",
  templateUrl: "./project-select.component.html",
})
export class ProjectSelectComponent implements OnInit {
  projects$: Observable<ApiListResult<IProject>>;

  navigation$!: Observable<{ page: number, limit: number }>;

  constructor(
    private fb: FormBuilder,
    private projectSvc: ProjectService,
    private folioSvc: FolioService,
    private modalSvc: BsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.navigation$ = this.route.queryParams
      .pipe(
        map(({ page = 1, limit = 10 }) => ({
          page,
          limit,
        }))
      )

    this.projects$ = this.navigation$
      .pipe(
        switchMap(navigation => this.projectSvc.getMyProjects({}, navigation.page, navigation.limit))
      );
  }

  ngOnInit() { }

  selectProject(project: IProject) {
    this.folioSvc.closeAllFolios()
    this.router.navigate(["/", "editor", project.id]);
    // this.sendValue(project);
  }

  newDossier() {
    this.modalSvc.show(ProjectCreateComponent)
  }

  // sendValue(val: any) {
  //   const reason: any = val;
  //   this.modalSvc.setDismissReason(reason);
  //   this.modal.hide();
  // }
}
