import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { FeaturesService } from '../services/features.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanActivate {
  constructor() { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return FeaturesService.isFeatureEnabled(next.data.feature)

  }
}
