import * as go from "gojs";
import { Pipe, PipeTransform } from "@angular/core";
import getsetFunctions from "./getset-functions";

@Pipe({
  name: "propValue",
})
export class PropValuePipe implements PipeTransform {
  transform(
    prop: any,
    propName: string,
    obj?: go.Part
  ): { label: string; value: string }[] {
    if (typeof (getsetFunctions as any)[prop[propName]] === "function")
      return (getsetFunctions as any)[prop[propName]](obj, prop);
    return prop[propName];
  }
}
