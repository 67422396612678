import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";
import { GojsAngularModule } from 'gojs-angular';
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ModalModule } from "ngx-bootstrap/modal";
import { AllPalettesComponent } from "./components/all-palettes.component";
import { PaletteComponent } from "./components/palette.component";
import { WallPaletteComponent } from "./components/wall-palette.component";
import { SquidPaletteComponent } from './components/squid-palette/squid-palette.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    GojsAngularModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),

    AngularSvgIconModule,
  ],
  providers: [],
  declarations: [PaletteComponent, WallPaletteComponent, AllPalettesComponent, SquidPaletteComponent, SquidPaletteComponent],
  exports: [PaletteComponent, WallPaletteComponent, AllPalettesComponent, SquidPaletteComponent],
})
export class PaletteModule { }
