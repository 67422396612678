import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { IFolio, IProject } from '@js-elec/js-elec-types';
import { BsModalRef } from "ngx-bootstrap/modal";
import { tap } from "rxjs/operators";
import { Floorplan } from "src/app/floorPlanner/diagram/floorplan-diagram";
import getsetFunctions from "src/app/floorPlanner/inspector/getset-functions";
import { FolioPropertyNames } from "../folio.constants";
import { FolioService, IOpenedFolio } from "../folio.service";

@Component({
  selector: "folio-properties",
  templateUrl: "./folio-properties.component.html",
})
export class FolioPropertiesComponent implements OnInit {
  @Input() project!: IProject;
  @Input() openedFolio!: IOpenedFolio;
  diagram!: Floorplan;

  form!: FormGroup;

  properties = Object.keys(FolioPropertyNames).filter(k => isNaN(Number(k)))

  layers: any;
  units: any;
  constructor(
    private folioSvc: FolioService,
    private fb: FormBuilder,
    private modal: BsModalRef
  ) { }

  ngOnInit() {
    this.diagram = this.openedFolio.diagram as Floorplan;
    this.layers = getsetFunctions.getLayers(this.diagram);
    this.units = getsetFunctions.getUnitsChoices();

    this.form = this.fb.group(
      {
        name: [this.openedFolio.folio.name, Validators.required],
        ...Object.keys(FolioPropertyNames)
          .filter(k => isNaN(Number(k)))
          .reduce((acc, k) => {
            acc[k] = new FormControl(this.openedFolio.diagram && getsetFunctions.getModelDataProperty(this.openedFolio.diagram, k as unknown as FolioPropertyNames)
              , Validators.required);
            return acc;
          }, {} as any)
      }
    );

    // function getScale(diag: go.Diagram) {
    //   const units: string = diag.model.modelData.units;
    //   const factor = this.model.modelData.unitsConversionFactor;

    //   this.convertUnits(
    //     DiagramUnitsConversionEnum[units],
    //     DiagramUnitsConversionEnum.inchs,
    //     1
    //   );
    //   (1 / DiagramUnitsConversionEnum[units]) * 92;
    //   return 0;
    // }
  }

  getUnitAbbr(): { label: string; value: string }[] {
    return getsetFunctions.getUnitsAbbreviation(this.diagram);
  }

  save() {
    Object.keys(FolioPropertyNames)
      .forEach(prop => getsetFunctions.setModelDataProperty(this.diagram, prop as unknown as FolioPropertyNames, this.form.value[prop]))

    this.folioSvc.updateFolio(this.project, { ...this.openedFolio, folio: { ...this.openedFolio.folio, name: this.form.value.name } })
      .pipe(
        tap(p => (this.folioSvc.selectedFolio$.value as IOpenedFolio).folio.name = this.form.value.name)
      )
      .subscribe()
    this.close()
  }

  close() {
    this.modal.hide()
  }


}
