import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UserRolesEnum } from "@js-elec/js-elec-types";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { UserService } from "src/app/user/user.service";

@Directive({
  selector: '[isInRole]'
})
export class IsInRoleDirective implements OnInit, OnDestroy {
  @Input('isInRole') roles: UserRolesEnum[] | UserRolesEnum = [];

  isVisible = false;
  stop$ = new Subject()

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authSvc: AuthService,
    private userSvc: UserService,
  ) { }

  ngOnInit() {
    this.authSvc.currentAuth
      .pipe(
        takeUntil(this.stop$),
      ).subscribe(auth => {
        if (!auth) {
          this.viewContainerRef.clear();
        }
        if (auth && this.userSvc.isInRole(auth.user, UserRolesEnum.rootAdmin)) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      })
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}