import * as go from "gojs";
import { FolioPropertyNames } from "src/app/folio/folio.constants";
import { Floorplan } from "../diagram/floorplan-diagram";
import {
  bottomSystemLayers, DiagramLayersEnum, DiagramUnitsAbbreviations, layersDisabled, LinkTypesEnum, topSystemLayers, WallTypesEnum
} from "../models/elements";
import { convertPixelsToUnits } from "../tools";

const getModelDataProperty = (layer: go.Diagram, prop: FolioPropertyNames) => {
  if (layer.model.modelData.properties)
    return layer.model.modelData.properties[prop];
}

const setModelDataProperty = (layer: go.Diagram, prop: FolioPropertyNames, value: any) => {
  if (layer.model.modelData.properties)
    layer.model.modelData.properties[prop] = value;
}

const getValue = (obj: go.Part, prop: any) => {
  let val = obj.data[prop.property];
  if (prop.type === "scale") {
    val = getWallLength(obj);
  }
  return val;
};

const setValue = (obj: go.Part, prop: any, data: any, commit = true): boolean => {
  if (commit && obj.diagram) {
    obj.diagram.startTransaction("set property");
  }
  if (data === undefined) return false;
  if (prop.readOnly === true) return false;
  obj.diagram && obj.diagram.model.setDataProperty(obj.data, prop.property, data);
  if (commit) {
    obj.diagram && obj.diagram.commitTransaction("set property");
  }
  return true;
};

const getDiagramValue = (diagram: go.Diagram, prop: string) => {
  let val = diagram.model.modelData[prop];
  return val;
};

const setDiagramValue = (diagram: go.Diagram, propName: string, data: any, commit = true): boolean => {
  if (commit) {
    diagram.startTransaction("set property");
  }
  if (data === undefined) return false;
  diagram.model.set(diagram.model.modelData, propName, data);
  if (commit) {
    diagram.commitTransaction("set property");
  }
  return true;
};

function getWallLength(link: go.Part) {
  let length = link.data.length
  if (!length) {
    length = Math.sqrt(
      ((link.data.startpoint.x - link.data.endpoint.x) * (link.data.startpoint.x - link.data.endpoint.x)) +
      ((link.data.startpoint.y - link.data.endpoint.y) * (link.data.startpoint.y - link.data.endpoint.y))
    );
    // length = convertPixelsToUnits(pxDist, link.diagram?.model.modelData.unitsConversionFactor).toFixed(2);
  }
  const unitsDist = convertPixelsToUnits(length, link.diagram?.model.modelData.unitsConversionFactor)
    .toFixed(2);
  return unitsDist.toString();
}

const getLayers = (obj: go.Diagram) => {
  const values = [];
  Object.values(DiagramLayersEnum)
  for (const item in DiagramLayersEnum) {
    if (!isNaN(Number(item)) && layersDisabled.indexOf(Number(item)) < 0) {
      if (Number(item) > bottomSystemLayers && Number(item) < topSystemLayers) {
        const layer = obj.findLayer(DiagramLayersEnum[item]);
        if (layer) values.push({ label: layer.name, value: layer.visible });
      }
    }
  }
  return values;
};

const setLayers = (diag: go.Diagram, data: any[]) => {
  if (diag !== null) {
    const values = getLayers(diag);
    data.forEach((visible, index) => {
      const layer = diag.findLayer(values[index].label);
      if (layer) {
        layer.visible = visible;
      }
    });
  }
};

const getGridSize = (diagram: go.Diagram) => {
  return diagram.model.modelData.gridSize;
};

const setGridSize = (diagram: Floorplan, data: string) => {
  diagram.changeGridSize(data, false);
};

const getGridHide = (diagram: go.Diagram) => {
  return !diagram.grid.visible;
};

const setGridHide = (diagram: Floorplan, hidden: boolean) => {
  diagram.grid.visible = !hidden;
};

const getUnitConversionFactor = (diagram: go.Diagram) => {
  return diagram.model.modelData.unitsConversionFactor;
};

const setUnitConversionFactor = (obj: go.Part, prop: any, data: string) => {
  (obj.diagram as Floorplan).changeUnitsConversion(
    data,
    obj.diagram?.model.modelData.gridSize,
    false
  );
};

const getUnitsChoices = () => {
  const units = [];
  for (const unit in DiagramUnitsAbbreviations) {
    if ((DiagramUnitsAbbreviations as any)[unit])
      units.push({ value: unit, label: unit });
  }

  return units;
};

const getUnits = (obj: go.Diagram) => {
  const u = obj.model.modelData.units;
  return (DiagramUnitsAbbreviations as any)[u] || DiagramUnitsAbbreviations.m;
};

const setUnits = (diagram: Floorplan, data: DiagramUnitsAbbreviations) => {
  diagram.changeUnits(data);
};

const getUnitsAbbreviation = (diagram: go.Diagram) => {
  return (diagram as Floorplan).model.modelData.unitsAbbreviation;
};

const getWallsChoices = () => {
  const walls = [];
  for (const wall in WallTypesEnum) {
    if (!isNaN(wall as any) && WallTypesEnum[wall])
      walls.push({ label: WallTypesEnum[wall], value: +wall });
  }

  return walls;
};

const getLinkTypesChoices = () => {
  const walls = [];
  for (const type in LinkTypesEnum) {
    if (!isNaN(type as any) && LinkTypesEnum[type])
      walls.push({ label: LinkTypesEnum[type], value: +type });
  }

  return walls;
};

const setWallValue = (obj: go.Part, prop: any, data: any) => {
  obj.diagram && obj.diagram.startTransaction("set property");
  if (setValue(obj, prop, data, false)) {
    switch (+data) {
      case WallTypesEnum.cloison_interieure:
        obj.diagram && obj.diagram.model.setDataProperty(obj.data, "thickness", 6);
        break;
      case WallTypesEnum.mur_interieur:
        obj.diagram && obj.diagram.model.setDataProperty(obj.data, "thickness", 10);
        break;
      case WallTypesEnum.mur_porteur:
        obj.diagram && obj.diagram.model.setDataProperty(obj.data, "thickness", 14);
        break;

      default:
        break;
    }
    obj.diagram && obj.diagram.commitTransaction("set property");
  }
};

export default {
  getValue,
  setValue,
  getDiagramValue,
  setDiagramValue,
  getModelDataProperty,
  setModelDataProperty,
  getLayers,
  setLayers,
  getGridSize,
  setGridSize,
  getGridHide,
  setGridHide,
  getUnitConversionFactor,
  setUnitConversionFactor,
  getUnits,
  setUnits,
  getUnitsChoices,
  getUnitsAbbreviation,
  getWallsChoices,
  setWallValue,
  getLinkTypesChoices,
};
