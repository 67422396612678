import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { AuthService } from "../../auth.service";

@Component({
  selector: "register-page",
  templateUrl: "register.component.html",
})
export class RegisterComponent implements OnInit {
  registerForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authSvc: AuthService
  ) { }

  ngOnInit() {
    this.registerForm = this.fb.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", Validators.required],
        passwordConfirm: ["", Validators.required],
      },
      { validator: this.MustMatch("password", "passwordConfirm") }
    );
  }

  private MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  register() {
    if (this.registerForm.valid) {
      this.authSvc
        .register({ ...this.registerForm.value, passwordConfirm: undefined })
        .pipe(
          switchMap((auth) =>
            this.authSvc.login(
              (this.registerForm.value.email as string).toLowerCase(),
              this.registerForm.value.password
            )
          )
        )
        .subscribe((user) => this.router.navigate(["/", "editor"]));
    }
  }
}
