import { Pipe, PipeTransform } from "@angular/core";
import { FeaturesService } from "../services/features.service";

@Pipe({
  name: "featureOption",
})
export class FeatureOptionPipe implements PipeTransform {
  constructor() { }

  transform(
    featureName: string,
    optionName: string
  ): string {
    return FeaturesService.getFeatureOption(featureName, optionName)
  }
}
