import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { FloorPlannerModule } from "../floorPlanner/floor-planner.module";
import { FolioModule } from "../folio/folio.module";
import { LayoutModule } from "../layout/layout.module";
import { PaletteModule } from "../palette/palette.module";
import { ProjectModule } from "../project/project.module";
import { SharedModule } from "../shared/shared.module";
import { MapPropertiesComponent } from "./components/map-properties.component";
import { NormeModalComponent } from "./components/norme-modal.component";
import { PrintPreviewComponent } from "./components/print-preview.component";
import { EditorComponent } from "./editor.component";
import { AppComponent } from "../test/app.component";
import { GojsAngularModule } from "gojs-angular";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    GojsAngularModule,

    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AngularSvgIconModule,

    SharedModule,
    FloorPlannerModule,
    LayoutModule,
    PaletteModule,
    ProjectModule,
    FolioModule,
  ],
  declarations: [EditorComponent, MapPropertiesComponent, PrintPreviewComponent, NormeModalComponent, AppComponent],
  exports: [],
})
export class EditorModule { }
