import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IUser, Models, OfferEnum, UserRolesEnum } from '@js-elec/js-elec-types';
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import * as lib from "../../../../../room-lib.json"

export type FeatureType = {
  id: string,
  children?: Array<FeatureType>
  options?: { [K: string]: any },
  enabled?: boolean,
  offer?: OfferEnum,
  inRoles?: Array<UserRolesEnum>
  roleAtLeast?: Array<UserRolesEnum>
}

@Injectable()
export class FeaturesService {
  static features$ = new BehaviorSubject<FeatureType[]>([])
  static symbolLib$ = new BehaviorSubject<any | null>(null)
  static squidLib$ = new BehaviorSubject<any | null>(null)
  static squidSubSchemas$ = new BehaviorSubject<any | null>(null)

  constructor(private httpClient: HttpClient) {
  }

  load() {
    // FeaturesService.squidLib$.next((lib as any).default)

    return this.httpClient.get<FeatureType[]>(environment.featuresUrl + "?t=" + new Date())
      .pipe(
        tap(features => FeaturesService.features$.next(features)),
        switchMap(features => {
          const squidFeature = FeaturesService.findFeature('squid', false, features);
          const symbolsFeature = FeaturesService.findFeature('symbols', false, features);
          return combineLatest([
            symbolsFeature && symbolsFeature.enabled !== false && symbolsFeature.options ? this.httpClient.get(symbolsFeature?.options?.symbolsFile + '?t=' + new Date()) : of(null),
            squidFeature && squidFeature.enabled !== false && squidFeature.options ? this.httpClient.get(squidFeature?.options?.symbolsFile + '?t=' + new Date()) : of(null),
            squidFeature && squidFeature.enabled !== false && squidFeature.options && squidFeature?.options?.subSchemasFile ? this.httpClient.get(squidFeature?.options?.subSchemasFile + '?t=' + new Date()) : of(null),
          ])
        }),
        tap(([symbols, squid, squidSubSchemas]) => {
          FeaturesService.symbolLib$.next(symbols)
          FeaturesService.squidLib$.next(squid)
          FeaturesService.squidSubSchemas$.next(squidSubSchemas)
        }))
  }

  saveFeatureFile(featureFile: 'lib' | 'room' | 'subschemas', json: any) {
    return this.httpClient.put<FeatureType[]>(environment.apiHost + '/config/jsonFile?file=' + featureFile, json)
      .pipe()
  }

  static findFeature(featureName: string, deep = false, rootFeatures = FeaturesService.features$.value) {
    let foundFeat;
    foundFeat = rootFeatures.find(f => f.id === featureName)
    if (!foundFeat && deep) {
      rootFeatures.forEach(f => {
        const feat = this.findFeature(featureName, deep, f.children || []);
        if (feat) {
          foundFeat = feat;
          return;
        }
      })
    }
    return foundFeat;
  }

  static hasFeatureAccess(featureName: string, user: Models.User, feat: FeatureType[] = FeaturesService.features$.value): boolean {
    const featureTree = featureName.split('.')
    if (!featureTree.length)
      return true;

    const feature = feat.find(f => f.id === featureTree[0]);
    if (feature) {
      let hasAccess = feature.enabled !== undefined && user.userHasOffer(feature.offer) ? feature.enabled : true;
      if (hasAccess === true && feature.inRoles && user.role !== undefined) {
        hasAccess = feature.inRoles.indexOf(user.role) >= 0;
      }
      if (hasAccess && featureTree.length > 1) {
        const a = this.hasFeatureAccess(featureTree.slice(1).join('.'), user, feature.children)
        return a
      }
      return hasAccess;
    }
    return false;
  }

  static isFeatureEnabled(featureName: string, feat: FeatureType[] = this.features$.value): boolean {
    const featureTree = featureName.split('.')
    if (!featureTree.length)
      return true;

    const feature = feat.find(f => f.id === featureTree[0]);
    if (feature) {
      let hasAccess = feature.enabled !== undefined ? feature.enabled : true;

      if (hasAccess && featureTree.length > 1) {
        const a = this.isFeatureEnabled(featureTree.slice(1).join('.'), feature.children)
        return a
      }
      return hasAccess;
    }
    return false;
  }

  static isFeatureAvailable(user: Models.User, featureName: string, feat: FeatureType[] = this.features$.value): boolean {
    const featureTree = featureName.split('.')
    if (!featureTree.length)
      return true;

    const feature = feat.find(f => f.id === featureTree[0]);
    if (feature) {
      let isAvailable = user.userHasOffer(feature.offer);

      if (isAvailable && featureTree.length > 1) {
        const a = this.isFeatureAvailable(user, featureTree.slice(1).join('.'), feature.children)
        return a
      }
      return isAvailable;
    }
    return false;
  }

  static getFeatureOption(featureName: string, optionName: string) {
    const feature = FeaturesService.findFeature(featureName, true);
    if (feature && feature.options) {
      return feature.options[optionName]
    }
    return undefined;
  }

}