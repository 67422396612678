import { Component, Input } from "@angular/core";

@Component({
  selector: 'error-page',
  template: `
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>{{code === 404 ? "Page non trouvée" : "Erreur"}}</h1>
        <p *ngIf="code === 404;else error">
          <a [routerLink]="['/']">Retour à l'accueil</a>
        </p>
        <ng-template #error>
        <p >
          Une erreur est survenue. Tentez de recharger la page ou <a [routerLink]="['/']">retournez à l'accueil</a>.
        </p>
        </ng-template>
      </div>
    </div>
  </div>
    `
})
export class ErrorPageComponent {
  @Input() code = 404
  constructor() {

  }
}