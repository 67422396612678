import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../auth.service";

@Component({
  selector: "login-page",
  templateUrl: "login.component.html",
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authSvc: AuthService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  submit() {
    if (this.form.valid) {
      this.authSvc
        .login(this.form.value.email, this.form.value.password)
        .subscribe((res) => {
          this.router.navigate(["/editor"]);
        });
    }
  }
}
