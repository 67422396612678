import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppLayoutComponent } from "./app-layout.component";
import { LayoutEditorComponent } from "./layout-editor.component";
import { ModalLayoutComponent } from "./modal-layout.component";

@NgModule({
  imports: [RouterModule],
  declarations: [
    AppLayoutComponent,
    LayoutEditorComponent,
    ModalLayoutComponent,
  ],
  exports: [AppLayoutComponent, LayoutEditorComponent, ModalLayoutComponent],
})
export class LayoutModule {}
