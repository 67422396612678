import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { FeaturesService } from "../services/features.service";

@Directive({
  selector: '[hasFeature]'
})
export class HasFeatureDirective implements OnInit, OnDestroy {
  @Input('hasFeature') featureName!: string;

  isVisible = false;
  stop$ = new Subject()

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authSvc: AuthService,
  ) { }

  ngOnInit() {
    this.authSvc.currentAuth
      .pipe(
        takeUntil(this.stop$),
      ).subscribe(auth => {
        let hasAccess = auth && FeaturesService.hasFeatureAccess(this.featureName, auth.user)

        if (hasAccess && !this.isVisible) {
          this.isVisible = true;
          let foundFeature = FeaturesService.findFeature(this.featureName, true)
          this.viewContainerRef.createEmbeddedView(this.templateRef, { $implicit: foundFeature });
        } else if (!hasAccess) {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}