import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from "angular-svg-icon";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { EditorModule } from "./editor/editor.module";
import { FloorPlannerModule } from "./floorPlanner/floor-planner.module";
import { FolioModule } from "./folio/folio.module";
import { httpInterceptorProviders } from "./http-interceptors";
import { LayoutModule } from "./layout/layout.module";
import { PaletteModule } from "./palette/palette.module";
import { ProjectModule } from "./project/project.module";
import { FeaturesService } from "./shared/services/features.service";
import { SharedModule } from "./shared/shared.module";
import { UserModule } from "./user/user.module";

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function configFactory(config: FeaturesService) {
  return () => config.load();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    RouterModule,

    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    AppRoutingModule,
    SharedModule,
    UserModule,
    FloorPlannerModule,
    LayoutModule,
    AuthModule,
    PaletteModule,
    EditorModule,
    ProjectModule,
    FolioModule,
  ],
  declarations: [AppComponent],
  providers: [
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: 'fr' },
    FeaturesService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [FeaturesService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
