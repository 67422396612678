import { Component, OnInit, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "modal-spinner",
  templateUrl: "./modal-spinner.component.html",
})
export class ModalSpinnerComponent implements OnInit {
  constructor(public activeModal: BsModalRef) { }
  // @Input() title!: string;
  // @Input() body!: string;
  // @Input() buttonLabel = "Ok";

  ngOnInit() { }
}
