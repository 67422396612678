export const findDPI = () => {
  let counter = 0;
  return findFirstPositive(
    (x: number) => (++counter, matchMedia(`(max-resolution: ${x}dpi)`).matches)
  );
};

// Binary search
// http://www.geeksforgeeks.org/find-the-point-where-a-function-becomes-negative/
function findFirstPositive(fn: (x: number) => any) {
  let start = 1;
  // tslint:disable-next-line: no-bitwise
  while (0 >= fn(start)) start <<= 1;
  // tslint:disable-next-line: no-bitwise
  return binSearch(fn, start >>> 1, start) | 0;
}

function binSearch(fn: (x: number) => number, min: number, max: number): number {
  if (max < min) return -1; // not found

  // tslint:disable-next-line: no-bitwise
  const mid = (min + max) >>> 1;
  if (0 < fn(mid)) {
    if (mid === min || 0 >= fn(mid - 1)) {
      return mid;
    }
    return binSearch(fn, min, mid - 1);
  }
  return binSearch(fn, mid + 1, max);
}
