import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "modal-info",
  templateUrl: "./modal-info.component.html",
})
export class ModalInfoComponent implements OnInit {
  @ViewChild('content', { static: true }) content!: ElementRef;
  constructor(public activeModal: BsModalRef) { }
  @Input() title!: string;
  @Input() body?: string;
  @Input() html?: string;
  @Input() buttonLabel = "Ok";

  ngOnInit() {
    if (this.content && this.html)
      this.content.nativeElement.innerHtml = (this.html)
  }
}
