import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiListResult, IProject } from '@js-elec/js-elec-types';
import { Observable } from "rxjs";
import { ProjectService } from "../project.service";

@Component({
  selector: "project-list",
  templateUrl: "./project-list.component.html",
})
export class ProjectListComponent implements OnInit, OnDestroy {
  projects$: Observable<ApiListResult<IProject>>;

  constructor(private projectSvc: ProjectService) {
    this.projects$ = this.projectSvc.getMyProjects();
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
