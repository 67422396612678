import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    templateUrl: 'norme-modal.component.html'
})
export class NormeModalComponent {
    constructor(public activeModal: BsModalRef) {

    }
}