import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";

import { AuthService } from "../auth/auth.service";
import { IAuth } from '@js-elec/js-elec-types';
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { NotificationService } from "../shared/services/notify.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private notifySvc: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.auth.getToken();

    // Clone the request and set the new header in one step.
    if (req.url.match(new RegExp(environment.apiHost)) && token !== null && !req.headers.get('Authorization')) {
      const authReq = req.clone({
        setHeaders: { Authorization: 'bearer ' + token, 'Access-Control-Allow-Origin': '*' },
      });
      return next.handle(authReq).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 402) {
            this.notifySvc.notification("error", "Vous devez souscrire à un abonnement pour pouvoir effectuer cette action")
          }
          throw (err);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
