import { Component, OnInit } from '@angular/core';
import { FeaturesService } from 'src/app/shared/services/features.service';
// import squidLib from "../../models/squid-lib.json";

@Component({
  selector: 'squid-palette',
  templateUrl: './squid-palette.component.html',
  styleUrls: ['./squid-palette.component.css']
})
export class SquidPaletteComponent implements OnInit {
  squidPaletteData = {
    nodeDataArray: FeaturesService.squidLib$.value.categories[0].symbols as any, //(squidLib as any).categories[0].symbols as any,
    // linkDataArray: [],
    // modelData: {}
  };

  constructor() {
  }

  ngOnInit(): void {


  }


}
