import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IProject } from '@js-elec/js-elec-types';
import { of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FolioService } from "src/app/folio/folio.service";
import { ProjectService } from "src/app/project/project.service";

@Component({
  selector: "map-properties",
  templateUrl: "./map-properties.component.html",
})
export class MapPropertiesComponent implements OnInit, OnDestroy {
  @ViewChild("uploader") uploader!: ElementRef;

  locked!: boolean
  showMeasures!: boolean
  destroy$ = new Subject();

  constructor(private projectSvc: ProjectService, private folioSvc: FolioService) {
    this.folioSvc.lockGrounds$
      .pipe(takeUntil(this.destroy$))
      .subscribe(l => this.locked = l);
  }

  switchLock() {
    this.folioSvc.lockGrounds$.next(!this.folioSvc.lockGrounds$.value)
  }

  switchShowMeasures() {
    this.folioSvc.showMeasures$.next(!this.folioSvc.showMeasures$.value)
  }

  uploadMap(file?: File) {
    if (!file)
      return of(undefined);
    return this.projectSvc
      .uploadMap(this.projectSvc.currentProject.value?.id as string, file)
      .subscribe((project: IProject) => {
        // (this.projectSvc.currentProject.value as IProject).groundImages = project.groundImages
        this.projectSvc.currentProject.next(project)
        this.uploader.nativeElement.value = "";
      });
  }

  // deleteMap(key: string) {
  //   return this.projectSvc
  //     .deleteMap(this.projectSvc.currentProject.value?.id as string, key)
  //     .subscribe((project) => {
  //       (this.projectSvc.currentProject.value as IProject).groundImages = project.groundImages
  //     });
  // }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
