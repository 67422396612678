import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-form-group-radio",
  templateUrl: "./form-group-radio.component.html",
  styleUrls: ["./form-group-radio.component.scss"],
})
export class FormGroupRadioComponent implements OnInit {
  @Input() label?: string;
  @Input() for!: string;
  constructor() {}

  ngOnInit() {}
}
