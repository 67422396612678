import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

type AlertType = "success" | "warning" | "error" | "info" | "show";

@Injectable()
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  private _defaultOptions = {
    closeButton: false,
    timeOut: 8000,
    extendedTimeOut: 1000,
    disableTimeOut: false,
    easing: "ease-in",
    easeTime: 300,
    enableHtml: false,
    progressBar: false,
    progressAnimation: "decreasing",
    positionClass: "toast-bottom-right",
    titleClass: "toast-title",
    messageClass: "toast-message",
    tapToDismiss: true,
    onActivateTick: false,
    maxOpened: 0,
    autoDismiss: false,
    iconClasses: {
      error: "toast-error",
      info: "toast-info",
      success: "toast-success",
      warning: "toast-warning",
    },
    newestOnTop: true,
    preventDuplicates: false,
  };
  get defaultOptions(): object {
    return this._defaultOptions;
  }
  set defaultOptions(options) {
    Object.assign(this._defaultOptions, options);
  }

  notification(
    alertType: "info" | "warning" | "success" | "error",
    message: string,
    title: string = "",
    options: object = {}
  ) {
    const mergedoptions = Object.assign({}, this.defaultOptions, options);
    this.toastr[alertType](message, title, mergedoptions);
  }
}
