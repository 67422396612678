import { Component, Input, Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-modal-confirm",
  templateUrl: "./modal-confirm.component.html",
})
export class ModalConfirmComponent {
  @Input() title!: string;
  @Input() body!: string;
  @Input() yesButton = "Oui";
  @Input() noButton = "Non";
  @Input() defaultIsYes = false;
  constructor(
    public activeModal: BsModalRef,
    public modalSvc: BsModalService
  ) { }

  sendValue(val: boolean) {
    const reason: any = val;
    this.modalSvc.setDismissReason(reason);
    this.activeModal.hide();
  }
}
