import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IProject } from '@js-elec/js-elec-types';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ProjectService } from "../project.service";

@Component({
  selector: "create-project",
  templateUrl: "./project-create.component.html",
})
export class ProjectCreateComponent implements OnInit {
  projectForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private modalSvc: BsModalService,
    public modal: BsModalRef,
    private projectSvc: ProjectService,
    private router: Router
  ) { }

  ngOnInit() {
    this.projectForm = this.fb.group({
      name: ["", Validators.required],
    });
  }

  createProject() {
    if (this.projectForm.valid) {
      this.projectSvc
        .createProject(this.projectForm.value as IProject)
        .subscribe((p) => {
          this.router.navigate(["/", "editor", p.id]);

          this.sendValue(p);
        });
    }
  }

  sendValue(val: any) {
    const reason: any = val;
    this.modalSvc.setDismissReason(reason);
    this.modal.hide();
  }
}
