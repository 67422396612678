import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { ProjectCreateComponent } from "../components/project-create.component";
import { ProjectService } from "../project.service";

@Injectable({
  providedIn: "root",
})
export class ProjectGuard implements CanActivate {
  constructor(
    private projectSvc: ProjectService,
    private modalSvc: BsModalService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.projectSvc.getMyProjects().pipe(
      switchMap((projects) => {
        if (projects.count === 0) {
          this.modalSvc.show(ProjectCreateComponent);

          return this.modalSvc.onHide.pipe(
            take(1),
            map((r) => !!r)
          );
        }
        return of(true);
      })
    );
  }
}
