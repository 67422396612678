import { Component, Input, OnInit, ViewChild } from "@angular/core";
import * as go from "gojs";
import {
  PaletteComponent as GoPaletteComponent
} from "gojs-angular";
import { FloorplanPalette } from "./floorplan-palette";

@Component({
  selector: "palette",
  templateUrl: "palette.component.html",
  styleUrls: ["palette.component.scss"],
})
export class PaletteComponent implements OnInit {
  @ViewChild("palette", { static: true })
  paletteComponent!: GoPaletteComponent;

  @Input() paletteId!: string;
  @Input() data!: { nodeDataArray: any[]; linkDataArray: any[]; modelData: any };

  constructor() { }

  ngOnInit() {
  }

  public requestUpdate() {
    this.paletteComponent.palette?.requestUpdate();
  }

  initPalette(): go.Palette {
    const fp = new FloorplanPalette();
    return fp;
    // palette.model = $(go.GraphLinksModel, {
    //   linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    // });
  }

  paletteModelChange = function (changes: go.IncrementalData) {
    return;
    // this.paletteNodeData = DataSyncService.syncNodeData(
    //   changes,
    //   this.paletteNodeData
    // );
    // this.paletteLinkData = DataSyncService.syncLinkData(
    //   changes,
    //   this.paletteLinkData
    // );
    // this.paletteModelData = DataSyncService.syncModelData(
    //   changes,
    //   this.paletteModelData
    // );
  };
}
