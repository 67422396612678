import * as go from "gojs";
import { Pipe, PipeTransform } from "@angular/core";
import getsetFunctions from "./getset-functions";

@Pipe({
  name: "propertyChoices",
})
export class PropertyChoicesPipe implements PipeTransform {
  transform(prop: any, obj?: go.Part): { label: string; value: string }[] {
    if (typeof prop.choices === "string")
      return (getsetFunctions as any)[prop.choices](obj, prop);
    return prop.choices;
  }
}
