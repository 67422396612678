import { IFolio } from '@js-elec/js-elec-types';
import { GaineLengthsEnum } from "../floorPlanner/models/elements";

export enum WallThicknessEnum {
  wall = 10,
  internalWall = 7,
  cloison = 4,
  divider = 2,
  scale = 3
}

export const defaultDiagramModelData = {
  units: "m",
  unitsAbbreviation: "m",
  unitsConversionFactor: 0.02,
  gridSize: 1,
  wallThickness: WallThicknessEnum.wall,
  properties: {
    hauteurPlafond: 250,
    hauteurInter: 110,
    hauteurInterExt: 110,
    hauteurPC: 30,
    // hauteurPCExt: 30,
    hauteurPCCredance: 110,
    hauteurPCHaute: 110,
    hauteurAppliques: 180,
    hauteurConduitsHauts: 260
  },
  preferences: {
    showWallGuidelines: true,
    showWallLengths: true,
    showWallAngles: true,
    showOnlySmallWallAngles: true,
    showGrid: true,
    gridSnap: false,
  },
}

export const SymbolTypeHeightMapping = {
  "Lampe": "hauteurPlafond",
  "Spot": "hauteurPlafond",
  "Spot Extérieur": "hauteurPlafond",
  "Dalle leds": "hauteurPlafond",
  "Eclairage": "hauteurPlafond",
  "Applique": "hauteurAppliques",
  "Eclairage fluo": "hauteurAppliques",
  "Eclairage applique": "hauteurAppliques",
  "Eclairage extérieur": "hauteurPlafond",
  "Lampe Extérieur": "hauteurPlafond",
  "Interrupteur": "hauteurInter",
  "Interrupteur à Variation": "hauteurInter",
  "Interrupteur à Voyant": "hauteurInter",
  "Interrupteur Va et Vient": "hauteurInter",
  "Interrupteur double": "hauteurInter",
  "Interrupteur et Va et Vient": "hauteurInter",
  "Va-et-vient": "hauteurInter",
  "Bouton Poussoir": "hauteurInter",
  "BP connecté": "hauteurInter",
  "Détecteur": "hauteurPlafond",
  "Détecteur mur": "hauteurAppliques",
  "Détecteur plafond": "hauteurPlafond",
  "Prise": "hauteurPC",
  "Prise de courant": "hauteurPC",
  "PC 16A 1.5mm²": "hauteurPC",
  "PC 16A 2.5mm²": "hauteurPC",
  "PC 16A 1.5mm² haute": "hauteurPCHaute",
  "PC 20A 2.5mm²": "hauteurPC",
  "PC cuisine": "hauteurPCCredance",
  "Prise simple": "hauteurPC",
  "Prise double": "hauteurPC",
  "Prise extérieur": "hauteurPC",
  "Prise USB": "hauteurPC",
  "Prise Spécialisé": "hauteurPC",
  "Prise haute": "hauteurPCHaute",
  "Prise simple haute": "hauteurPCHaute",
  "Prise double haute": "hauteurPCHaute",
  "Prise cuisine": "hauteurPCCredance",
  "Prise double crédence": "hauteurPCCredance",
  "Prise Informatique": "hauteurPC",
  "Prise TV": "hauteurPC",
  "Prise Téléphonique": "hauteurPC",
  "VMC": "hauteurPlafond",
  "Hotte": "hauteurAppliques",
  "Four": "hauteurPC",
  "Plaque de cuisson": "hauteurPC",
  "Lave vaisselle": "hauteurPC",
  "Lave linge": "hauteurPC",
  "Sèche linge": "hauteurPC",
  "Chauffe eau": "hauteurPCHaute",
  "Chauffe-Eau": "hauteurPCHaute",
  "Chaudière": "hauteurPCHaute",
  "Pompe à chaleur": "hauteurPC",
  "Circuit spécialisé": "hauteurPC",
  "PC 32A": "hauteurPC",
  "Contact Sec": "hauteurPC",
  "Liaison Tableau": "hauteurPC",
  "Contact Tableau": "hauteurPC",
  "Tableau Mono": "hauteurPC",
  "Boite Pieuvre": "hauteurConduitsHauts",
  "Terre": "hauteurPC",
  "Chauffage 1.5mm²": "hauteurPC",
  "Chauffage": "hauteurPC",
  "Convecteur Fil Pilote": "hauteurPC",
  "Autre circuit 2A": "hauteurInter",
  "Box domotique": "hauteurInter",
  "Ventilation": "hauteurConduitsHauts",
  "Volet roulant": "hauteurInter",
  "BSO": "hauteurInter",
  "Climatisation": "hauteurAppliques",
  "Store": "hauteurInter"
}

export enum FolioPropertyNames {
  "hauteurPlafond" = "hauteurPlafond",
  "hauteurInter" = "hauteurInter",
  "hauteurInterExt" = "hauteurInterExt",
  "hauteurPC" = "hauteurPC",
  // "hauteurPCExt"=// "hauteurPCExt",
  "hauteurPCCredance" = "hauteurPCCredance",
  "hauteurPCHaute" = "hauteurPCHaute",
  "hauteurAppliques" = "hauteurAppliques",
  "hauteurConduitsHauts" = "hauteurConduitsHauts",
}

export type FolioProperties = {
  hauteurPlafond: number
  hauteurInter: number
  hauteurInterExt: number
  hauteurPC: number
  // hauteurPCExt: number
  hauteurPCCredance: number
  hauteurPCHaute: number
  hauteurAppliques: number
  hauteurConduitsHauts: number
}

export const defaultFolio: IFolio = {
  name: "Folio",
  modelData: {
    units: "m",
    unitsAbbreviation: "m",
    elementSize: 0.60,
    textSize: 7,
    unitsConversionFactor: 0.02,
    gridSize: 1,
    wallThickness: 10,
    properties: {
      hauteurPlafond: 250,
      hauteurInter: 110,
      hauteurInterExt: 110,
      hauteurPC: 30,
      // hauteurPCExt: 30,
      hauteurPCCredance: 110,
      hauteurPCHaute: 110,
      hauteurAppliques: 180,
      hauteurConduitsHauts: 260,
    } as FolioProperties,
    preferences: {
      showWallGuidelines: true,
      showWallLengths: true,
      showWallAngles: true,
      showOnlySmallWallAngles: true,
      showGrid: true,
      gridSnap: true,
    },
  },
  nodeDataArray: [],
  linkDataArray: [],
};

export const gaineLengths: { [k in GaineLengthsEnum]: { color: string, length: number } } = {
  length1650: {
    color: '#7F0000',
    length: 16.50
  },
  length1150: {
    color: '#FF6A00',
    length: 11.50
  },
  length1050: {
    color: '#B200FF',
    length: 10.50
  },
  length950: {
    color: '#00B050',
    length: 9.50
  },
  length850: {
    color: '#0000FF',
    length: 8.50
  },
  length750: {
    color: '#0094FF',
    length: 7.50
  },
}