import { Pipe, PipeTransform } from "@angular/core";
import { UserRolesEnum } from '@js-elec/js-elec-types';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Pipe({
    name: "isInRole",
})
export class IsInRolePipe implements PipeTransform {
    constructor(private authSvc: AuthService) { }

    transform(
        role: UserRolesEnum
    ): Observable<boolean> {
        return this.authSvc.currentAuth
            .pipe(
                map(auth => auth !== null && auth.user.role <= role)
            )
    }
}
