import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notify.service';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'app-password-request',
  templateUrl: './password-request.component.html',
  styleUrls: ['./password-request.component.css'],
})
export class PasswordRequestComponent implements OnInit {
  passwordForm!: FormGroup;
  isSubmitted = false;
  sent = false;
  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private notifySvc: NotificationService
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit() {
    this.isSubmitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.userSvc.askPassword(this.passwordForm.value.email).subscribe(
      (_) => {
        this.sent = true;
      },
      (_: HttpErrorResponse) => {
        this.notifySvc.notification('error', `Une erreur s'est produite`);
      }
    );
    this.isSubmitted = false;
  }
}
