import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiListResult, IFolio, IProject } from '@js-elec/js-elec-types';
import { BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, delay, last, map, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FolioService } from "../folio/folio.service";
import { ModalSpinnerComponent } from "../shared/components/modals/modal-spinner.component";
import { NotificationService } from "../shared/services/notify.service";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  lsProjectKey = "project";
  currentProject = new BehaviorSubject<IProject | null>(null);

  constructor(
    private httpClient: HttpClient,
    private notifySvc: NotificationService,
    private folioSvc: FolioService,
    private modalSvc: BsModalService
  ) { }

  getProjects(
    query: any = {},
    page = 1,
    limit = 10
  ): Observable<ApiListResult<IProject>> {
    let queryString = Object.keys(query)
      .filter((prop) => !!query[prop] && query[prop] !== "")
      .reduce((acc, k) => {
        return (acc += `${k}=${query[k]}&`);
      }, "");
    queryString += `page=${page}`;
    return this.httpClient.get<ApiListResult<IProject>>(
      `${(environment as any).apiHost}/project?${queryString}`
    );
  }

  getMyProjects(
    query: any = {},
    page = 1,
    limit = 10
  ): Observable<ApiListResult<IProject>> {
    let queryString = Object.keys(query).reduce((acc, k) => {
      return (acc += `${k}=${(query)[k]}&`);
    }, "");
    queryString += `page=${page}&limit=${limit}`;
    return this.httpClient.get<ApiListResult<IProject>>(
      `${(environment as any).apiHost}/project/mine?${queryString}`
    );
  }

  getProject(id: string): Observable<IProject> {
    return this.httpClient.get<IProject>(
      `${(environment as any).apiHost}/project/${id}`
    );
  }

  findFolioFromId(id: string) {
    return (this.currentProject.value as IProject).folios.find(f => f.id === id)
  }

  updateProject(id: string, project: IProject): Observable<IProject> {
    return this.getMyProjects()
      .pipe(
        switchMap(projects => {
          const doublon = projects.rows.find(p => p.name === project.name && p.id !== id);
          if (doublon) {
            project.name = doublon.name + ' 2';
          }
          return this.httpClient
            .put<IProject>(`${(environment as any).apiHost}/project/${id}`, project)
            .pipe(
              catchError((err) => {
                this.notifySvc.notification(
                  "error",
                  `Une erreur s'est produite à l'enregistement`
                );
                throw err;
              }),
              // !!! WARN: Object.assign may cause pointer conflict on folios list and folios tab. 
              // tap((projectUpdated) => {
              //   if (
              //     this.currentProject.value &&
              //     this.currentProject.value.id &&
              //     this.currentProject.value.id === projectUpdated.id
              //   ) {
              //     Object.assign(this.currentProject.value, projectUpdated);
              //   }
              // })
            );
        })
      )
  }

  validateProject(id: string) {
    return this.httpClient
      .put<IProject>(`${(environment as any).apiHost}/project/${id}/validate`, {})
      .pipe(
        catchError((err) => {
          this.notifySvc.notification(
            "error",
            `Une erreur s'est produite à l'enregistement`
          );
          throw err;
        }),
        tap(_ => {
          this.notifySvc.notification(
            "success",
            `Votre projet est maintenant validé`
          );
        })
      )
  }

  createProject(project: IProject): Observable<IProject> {
    return this.getMyProjects()
      .pipe(
        switchMap(projects => {
          const doublon = projects.rows.find(p => p.name === project.name);
          if (doublon) {
            project.name = doublon.name + ' 2';
          }
          return this.httpClient
            .post<IProject>(`${(environment as any).apiHost}/project`, project)
            .pipe(
              catchError((err) => {
                this.notifySvc.notification("error", `Une erreur s'est produite`);
                throw err;
              })
            );
        })
      )
  }

  deleteProject(projectId: string): Observable<IProject> {
    return this.httpClient
      .delete<IProject>(`${(environment as any).apiHost}/project/${projectId}`)
      .pipe(
        catchError((err) => {
          this.notifySvc.notification("error", `Une erreur s'est produite`);
          throw err;
        })
      );
  }

  uploadMap(projectId: string, file: any) {
    const formData = new FormData();
    formData.append("groundimage", file, file.name);
    return this.httpClient
      .post<IProject>(
        `${(environment as any).apiHost}/project/${projectId}/groundimages`,
        formData,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        tap(
          _ => this.modalSvc.show(ModalSpinnerComponent, { class: 'spinnerFile' })
        ),
        last(),
        catchError((err) => {
          this.notifySvc.notification(
            "error",
            `Une erreur s'est produite à l'enregistement`
          );
          throw err;
        }),
        tap(_ => this.modalSvc.hide(1)),
        map((res: any) => res.body)
      );
  }

  deleteMap(projectId: string, fileName: string) {
    return this.httpClient.delete<IProject>(
      `${(environment as any).apiHost}/project/${projectId}/pictures/?fileName=${fileName}`
    );
  }

  getMaterials(project: IProject): { folio: string, materials: any[] }[] {
    const materials: any[] = [];
    project.folios.forEach((folio: IFolio) => {
      materials.push({ folio: folio.name, materials: this.folioSvc.getMaterials(folio) })
    })
    return materials;
  }

  getConduits(project: IProject): { folio: string, conduits: any[] }[] {
    const conduits: any[] = [];
    project.folios.forEach((folio: IFolio) => {
      conduits.push({ folio: folio.name, conduits: this.folioSvc.getConduits(folio) })
    })
    return conduits;
  }
}
