import { Location } from "@angular/common";
import {
  HttpClient, HttpErrorResponse, HttpHeaders
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { NotificationService } from "../shared/services/notify.service";
import { UserService } from "../user/user.service";
import { IAuth, IUser, Models } from "@js-elec/js-elec-types/dist";
import { Model } from "gojs";


@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentAuth = new BehaviorSubject<Models.Auth | null>(null);

  lsAuthKey = 'auth';
  lsAuthAsKey = 'authAs';
  apiUrl = `${(environment as any).apiHost}`;

  constructor(
    private httpClient: HttpClient,
    private notifySvc: NotificationService,
    private userSvc: UserService,
    private location: Location
  ) { }

  register(user: Models.User): Observable<Models.User> {
    return this.httpClient.post<IUser>(
      `${environment.apiHost}/user/register`,
      user
    ).pipe(map(user => new Models.User(user)));
  }

  remoteRegister(email: string): Observable<Models.User> {
    return this.httpClient.post<IUser>(
      `${environment.apiHost}/user/remoteRegister`,
      { email: email.trim().toLocaleLowerCase() }
    ).pipe(map(user => new Models.User(user)));;
  }

  getToken() {
    let key = this.lsAuthKey;
    if (localStorage.getItem(this.lsAuthAsKey)) {
      key = this.lsAuthAsKey
    }
    let authStorage: string | null = localStorage.getItem(key);
    let tokenParams = this.location.path().match(/token=([\w\d-\.]+)/);
    if (authStorage !== null) {
      const auth = JSON.parse(authStorage) as IAuth;
      return auth.token;
    } else if (tokenParams) {
      return tokenParams[1];
    }
    return null;
  }

  login(email: string, password: string): Observable<IAuth> {
    return this.httpClient
      .post<IAuth>(`${environment.apiHost}/auth/login`, { email, password })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.notifySvc.notification(
              "error",
              "Email ou mot de passe incorrect. Souhaitez-vous demander un nouveau mot de passe ?"
            );
          }
          return throwError("Echec de connexion");
        }),
        map(auth => new Models.Auth(auth)),
        tap((auth) => {
          this.saveAuth(auth);
          this.currentAuth.next(auth);
        })
      );
  }

  loginAs(email: string): Observable<Models.Auth> {
    return this.httpClient
      .post<IAuth>(`${this.apiUrl}/auth/loginAs`, { email })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.notifySvc.notification(
              'error',
              'Email incorrect'
            );
          }
          return throwError('Echec de connexion');
        }),
        map(auth => new Models.Auth(auth)),
        tap((auth) => {
          this.saveAuth(auth, true);
          this.currentAuth.next(auth);
        })
      );
  }

  isLoggedInAs() {
    return localStorage.getItem(this.lsAuthAsKey) !== null;
  }

  logout() {
    if (localStorage.getItem(this.lsAuthAsKey)) {
      localStorage.removeItem(this.lsAuthAsKey);
      this.loadAuth();
    } else {
      localStorage.removeItem(this.lsAuthKey);
      this.currentAuth.next(null);
    }
  }

  saveAuth(auth: Models.Auth, authAs = false) {
    localStorage.setItem(authAs ? this.lsAuthAsKey : this.lsAuthKey, JSON.stringify(auth));
  }

  loadAuth(): Observable<IAuth | null> {
    // let authStorage: string | null = localStorage.getItem(this.lsAuthKey);
    let token = this.getToken();
    if (!token) {
      this.logout();
      return of(null);
    }
    // const auth = JSON.parse(authStorage) as IAuth;
    return this.userSvc.getMe().pipe(
      map((user) => ({ token: token as string, user: new Models.User(user) })),
      tap((auth) => this.currentAuth.next(auth))
    );
  }

  isExpired(auth: IAuth) {
    return false;
  }

  // isGranted(user: IUser, keys: string[], restrictions = allRestrictions): boolean {
  //   if (!keys || keys.length === 0) {
  //     return false;
  //   }
  //   const currentKey = keys[0];
  //   const otherKeys = keys.slice(1, keys.length);
  //   const restriction = restrictions.find(r => r.name === currentKey);
  //   if (!restriction) {
  //     return false;
  //   }

  //   let granted;
  //   if (restriction.exactly) {
  //     granted = restriction.exactly.indexOf(user.role) >= 0;
  //   }
  //   else if (restriction.atLeast) {
  //     granted = user.role <= restriction.atLeast;
  //   } else if (restriction.atBest) {
  //     granted = user.role >= restriction.atBest;
  //   }
  //   if (granted !== false && otherKeys.length > 0) {
  //     if (!restriction.children) {
  //       granted = false;
  //     } else {
  //       granted = this.isGranted(user, otherKeys, restriction.children);
  //     }
  //   }
  //   return granted || false;

  // }
}
