import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authSvc.loadAuth().pipe(
      map((auth) => {
        if (!auth) {
          this.router.navigate(['/', 'login']);
          return false;
        }
        return true;
      }),
      catchError(() => {
        this.router.navigate(['/', 'connect', 'login']);
        return of(false);
      })
    );
  }
}
