import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ProjectSelectComponent } from "../project/components/project-select.component";
import { ProjectService } from "../project/project.service";

@Component({
  selector: "layout-editor",
  templateUrl: "./layout-editor.component.html",
})
export class LayoutEditorComponent implements OnDestroy {
  destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private modalSvc: BsModalService,
    private projectSvc: ProjectService
  ) {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      if (this.route.children.length === 0) {
        this.modalSvc.show(ProjectSelectComponent);
        // this.modalSvc.onHide.pipe(take(1));
      } else {
        this.projectSvc
          .getProject(this.route.snapshot.children[0].params.projectId)
          .subscribe((project) => this.projectSvc.currentProject.next(project));
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
