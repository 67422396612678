
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notify.service';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private notifySvc: NotificationService
  ) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validator: passwordConfirming }
    );

    function passwordConfirming(c: AbstractControl): { invalid: boolean } | undefined {
      if (c.get('password')?.value !== c.get('confirmPassword')?.value) {
        return { invalid: true };
      }
      return undefined;
    }
  }

  resetPassword() {
    const token = this.route.snapshot.queryParams.token;
    if (this.resetForm.valid) {
      this.userSvc
        .changePasswordWithToken(token, this.resetForm.value.password)
        .subscribe(
          (user) => {
            this.notifySvc.notification(
              'success',
              `Mot de passe changé avec succès !`
            );
            this.router.navigate(['/', 'auth', 'login']);
          },
          (error: HttpErrorResponse) => {
            this.notifySvc.notification(
              'error',
              `Une erreur s'est produite, votre lien unique est peut-être arrivé à expiration.`
            );
          }
        );
    }
  }
}