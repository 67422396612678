import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NotificationService } from "../shared/services/notify.service";
import { SharedModule } from "../shared/shared.module";
import { AuthRoutingModule } from "./auth-routing.module";
import { AuthService } from "./auth.service";
import { AuthLayoutComponent } from "./components/auth-layout/auth-layout.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { LoginComponent } from "./components/login/login.component";
import { PasswordRequestComponent } from "./components/password-request/password-request.component";
import { RegisterComponent } from "./components/register/register.component";
import { RemoteRegisterComponent } from "./components/remote-register/remote-register.component";

@NgModule({
  providers: [AuthService, NotificationService],
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,

    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    AuthLayoutComponent,
    LoginComponent,
    RegisterComponent,
    PasswordRequestComponent,
    ChangePasswordComponent,
    RemoteRegisterComponent
  ],
})
export class AuthModule { }
